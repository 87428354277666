import React from 'react';

const Footer = props => {
  return (
    <section id="footer" className="footer">
        <div className='footer-section footer-contact'>
          <div>
            <a href="https://www.instagram.com/jane.the.great.dane/" className='footer-social-icon'>
              <i class="fa fa-instagram"></i>
            </a>
          </div>
        </div>
        <div className='footer-copy'>
          <span><em>© 2023 Taylor McCormick | All rights reserved.</em></span>
        </div>
    </section>
  );
}

export default Footer;
