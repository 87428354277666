  import logo from '../../jane_transparent.png';
  import book_cover from '../../book_cover.png';
  import '../../App.css';
  import React from 'react';
  
  const Home = props => {
    return (
      
      <div className="App">
        <section className="App-header">
          <div class='title-container'>
            <h1 class='title'>Jane the Dane</h1>
          </div>
          <div className='App-logo-container'>
            <img src={logo} className="App-logo" alt="logo" />
          </div>
        </section>
  
        <section id="book-section" className="book-section">
      
          <h1 className='section-header'>Buy the Book!</h1>
            <div class="container">
              <img src={book_cover} className="book" alt="Jane the Dane: The Story of a Rescue Dog" />
              <div class="book-description">
                <p>Jane the Dane is a delightful children's book based on a true story of Jane's journey from a shelter to her forever home. Join Jane as she explores every corner of her new space, filled with joy, new toys, and endless tail-wagging happiness.</p>
                <stripe-buy-button
                  buy-button-id="buy_btn_1OEOlCIgjoiJSH41qbiJL9s8"
                  publishable-key={process.env.REACT_APP_STRIPE_API_KEY}
                >
                </stripe-buy-button>
              </div>
            </div>
        </section> 
      </div>
    );
  }
  
  export default Home;
  